import {
    Box,
    Container,
    SimpleGrid,
    Image,
    Flex,
    Heading,
    Text,
    Stack,
    Icon,
    List,
    ListItem,
    ListIcon,
} from '@chakra-ui/react';
import {
    IoCloudDoneSharp,
    IoCar,
    IoFlashSharp
} from 'react-icons/io5';
import { MdCheckCircle } from "react-icons/md"
export { Head } from "../seo";

const Feature = ({ idTag, title, text, icon }) => {
    return (
        <Stack id={idTag}>
            <Flex
                w={16}
                h={16}
                align={'center'}
                justify={'center'}
                color={'white'}
                rounded={'full'}
                bg={'gray.200'}
                mb={1}>
                {icon}
            </Flex>
            <Heading as={"h3"} fontSize={"3xlg"} fontWeight={600}>{title}</Heading>
            <Text color={'gray.600'}>{text}</Text>
        </Stack>
    );
};

export default function Features() {
    return (
        <>
            <Container maxW={'5xl'} py={12}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                    <Stack spacing={4} id={"custom-design-and-branding"}>
                        <Heading as="h1" size={"4xl"}>Custom Websites for Parking</Heading>
                        <Text color={'gray.600'} fontSize={'xl'}>
                            Custom designed website highlights your facility and sets it apart from competitors.
                        </Text>
                    </Stack>
                    <Image
                        alt={'feature image'}
                        src={
                            'https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                        }
                        objectFit={'cover'}
                    />
                </SimpleGrid>
                <Box p={10}>
                    <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                        <Feature
                            icon={<Icon as={IoCar} color={"gold.500"} w={10} h={10} />}
                            title={'Designed for Parking'}
                            text={
                                `Full-service design focused on the getting drivers into your facility.`
                            }
                        />
                        <Feature
                            icon={<Icon as={IoFlashSharp} w={10} h={10} color="brand.500" />}
                            title={'Custom URL'}
                            text={
                                `Your facility deserves to be on the Internet—don't settle for a page on someone else's website.`
                            }
                        />
                        <Feature
                            icon={<Icon as={IoCloudDoneSharp} w={10} h={10} color={`purple.500`} />}
                            title={'Industry leading practices'}
                            text={
                                `Parkify keeps you ahead of your competition.`
                            }
                        />
                    </SimpleGrid>
                </Box>
            </Container >
            <Container maxW={'5xl'} py={12}>
                <SimpleGrid
                    columns={{ base: 1, md: 2 }}
                    spacing={10}
                >
                    <Stack spacing={4} id={"built-for-mobile"}
                        order={{ md: 1 }}
                    >
                        <Heading as="h1" size={"4xl"}>Your parkers are on their phones</Heading>
                        <Text color={'gray.600'} fontSize={'xl'}>
                            Mobile-optimized design puts crucial parking information right where drivers need it.
                        </Text>

                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={MdCheckCircle} color='purple.500' />
                                Responsive design looks great on screens of all sizes.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={MdCheckCircle} color='purple.500' />
                                Deep-links to navigation apps get more parkers into your facility.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={MdCheckCircle} color='purple.500' />
                                Clearly labeled access hours and enforcement rules.
                            </ListItem>
                        </List>
                    </Stack>
                    <Image
                        order={{ md: 0 }}
                        alt={'feature image'}
                        src={
                            'https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                        }
                        objectFit={'cover'}
                    />
                </SimpleGrid>
            </Container >
            <Container maxW={'5xl'} py={12}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                    <Stack spacing={4} id={"seo"}
                    >
                        <Heading as="h1" size={"4xl"}>SEO Built-In</Heading>
                        <Text color={'gray.600'} fontSize={'xl'}>
                            Our engineers are experts at SEO, JSON-LD, and Open-Graph tags. We do the heavy lifting to ensure that your website
                            is up to date with the latest Internet standards and can be indexed by search engines, navigation apps, and other internet services.
                        </Text>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={MdCheckCircle} color='gold.500' />
                                With over a decade of SEO experience we know what works and what doesn't.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={MdCheckCircle} color='gold.500' />
                                Our engineers are fluent in XML, JSON-LD, and Open-Graph Tags so you don't have to be.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={MdCheckCircle} color='gold.500' />
                                Beautiful link previews increase conversation rates.
                            </ListItem>
                        </List>
                    </Stack>
                    <Image
                        alt={'feature image'}
                        src={
                            'https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                        }
                        objectFit={'cover'}
                    />
                </SimpleGrid>
            </Container >
            <Container maxW={'5xl'} py={12}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                    <Stack spacing={4} id={"local-business-listings"} order={{ md: 1 }}>
                        <Heading as="h1" size={"4xl"}>Local Business Listings</Heading>
                        <Text color={'gray.600'} fontSize={'xl'}>
                            What does your facility looks like on Google Maps? Your customers have looked up driving directions well before they
                            get into the car.
                        </Text>
                        <List spacing={"3"}>
                            <ListItem>
                                <ListIcon as={MdCheckCircle} color='brand.500' />
                                Automatic management of Google Maps Pins saves you time.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={MdCheckCircle} color='brand.500' />
                                Accurate hours of operation and helpful photos ensure more parkers reach your facility.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={MdCheckCircle} color='brand.500' />
                                More attribution will give you a better search ranking.
                            </ListItem>
                        </List>
                    </Stack>
                    <Image
                        order={{ md: 0 }}
                        alt={'feature image'}
                        src={
                            'https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80'
                        }
                        objectFit={'cover'}
                    />
                </SimpleGrid>
            </Container >
        </>
    );
}
